import Bugsnag, { Config } from '@bugsnag/js'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  return process.env.RAILS_ENV === 'development' ? { logger: null } : {}
}

Bugsnag.start({
  apiKey: '06275a0dd671f984bb6de12bf80a7ef7',
  releaseStage: process.env.RAILS_ENV,
  enabledReleaseStages: ['staging', 'production'],
  ...loggerOptions()
})

